/* PDFSinglePage.css */

.pdf-page {
  position: relative;
}

.page-order {
  position: absolute;

  left: 5px;


  padding: 2px 5px;
  border-radius: px;

}

.magnify-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;
  z-index: 10;
  /* Ensure the button is on top */
}

.magnify-button:hover {
  background: rgba(0, 0, 0, 0.7);
}