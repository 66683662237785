.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.drop-area {
  padding: 40px;
  background-color: rgba(47, 165, 114, 0.3);
  border: 2px dotted #2FA572;
  border-radius: 20px;
  text-align: center;
  color: white;
  cursor: pointer;
}

.drop-area p {
  margin: 0;
}

.drop-area label {
  background-color: #2FA572;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  margin-top: 10px;
}

.drop-area label:hover {
  background-color: #106A43;
}

.error-message {
  color: red;
  margin-top: 10px;
}
