.App {
  text-align: center;
  background-color: #121212; /* Ensure the entire app background is dark */
  min-height: 100vh; /* Ensure it covers the full height */
}

.content {
  padding-top: 70px; /* Add padding to ensure content starts below the fixed navbar */
}

.content.blurred {
  filter: blur(5px);
  pointer-events: none;
}

body.dark-mode {
  background-color: #121212; /* Ensure the entire app background is dark */
  color: #ffffff;
}

.drop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  z-index: 1000;
}

.drop-overlay p {
  margin: 0;
}
