.background-slots-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1200px; /* Ensure it matches the GridLayout width */
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Ensure it has rows of 4 */
    gap: 10px;
    z-index: 0; /* Ensure it stays behind the PDF items */
  }
  
  .background-slot {
    width: 100%;
    height: 300px;
    border: 1px dashed #ccc;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .slot-number {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
  }
  