/* PDFModal.css */

.modal {
  position: fixed;
  z-index: 1100; /* Ensure this is higher than the navbar's z-index */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative; /* Position relative to contain the close button */
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: auto; /* Width will adjust based on content */
  max-width: 100%; /* Ensure it doesn't exceed the viewport width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for better visibility */
  max-height: 90%; /* Ensure the modal doesn't exceed the viewport height */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds the modal height */
}

.close {
  position: sticky; /* Sticky positioning for the close button */
  top: 25px; /* Position from the top */
  left: 5000px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  background-color: #fefefe; /* Match modal content background */
  z-index: 1200; /* Ensure it is above the content */
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}
