/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 1); /* Black when at the top */
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: background-color 0.3s ease;
  z-index: 1000;
}
.navbar-logo {
  width: 40px; /* Adjust size as needed */
  height: auto;
  margin-right: 10px;
}
.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.7); /* More transparent when scrolling */
}

.navbar-brand {
  font-size: 24px;
  font-weight: bold;
}

.combine-button, .clear-button {
  background-color: #2FA572;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 20px; /* Adjust margin to space out buttons */
}

.combine-button:hover, .clear-button:hover {
  background-color: #106A43;
}
