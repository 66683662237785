.pdf-grid-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #121212; /* Ensure the background color matches the rest of the page */
  box-sizing: border-box; /* Ensure padding is included in the total width and height */
}

.pdf-grid-container {
  position: relative;
  z-index: 1;
  width: 1200px; /* Ensure the container takes full width */
}

.react-grid-layout {
  width: 100%; /* Ensure the grid takes the full width */
  max-width: 1200px; /* Maximum width to avoid pushing content off-screen */
  margin: 0 auto; /* Center the grid horizontally */
}

.pdf-page-container {
  
  overflow: hidden;
  background-color: transparent; /* Remove white background */
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative; /* Ensure relative positioning for the magnify button */
}

.pdf-page-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.page-number {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
}

.pdf-page img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}
